import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, styled, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import DonationCard from "./donation-card/DonationCard";
import { useRouter } from "next/router";
import "antd/dist/antd.css";
import CurrencyFormat from "react-currency-format";
import { axiosInstance } from "../config/axios-instance";
import dayjs from "dayjs";
import "dayjs/locale/id";
import relativeTime from "dayjs/plugin/relativeTime";
import { Spin } from "antd";
import CardCampaign from "./CardCampaign";
import PrevIconButton from './../public/Icon/prev-icon-button';
import NextIconButton from './../public/Icon/next-icon-button';
import ItemsCarousel from "react-items-carousel";

dayjs.locale("id");
dayjs.extend(relativeTime);

function PrevButton(props){
  const { style } = props;
  return (
    <PrevIconButton style={{...style, display: 'block', zIndex: 10, top: '50%', left: '70px', width: '58px', height: '58px', position: 'absolute'}}/>
  )
}

function NextButton(props){
  const { style } = props;
  return (
    <NextIconButton style={{...style, display: 'block', zIndex: 10, top: '50%', right: '70px', width: '58px', height: '58px', position: 'absolute'}}/>
  )
}

function PrevMobButton(props){
  const { style } = props;
  return (
    <PrevIconButton style={{...style, display: 'block', zIndex: 10, top: '50%', left: '12px', width: '39px', height: '39px', position: 'absolute'}}/>
  )
}

function NextMobButton(props){
  const { style } = props;
  return (
    <NextIconButton style={{...style, display: 'block', zIndex: 10, top: '50%', right: '12px', width: '39px', height: '39px', position: 'absolute'}}/>
  )
}

function BannerSectionOne(props) {
  const router = useRouter();
  const [isMobile, setisMobile] = React.useState(false);
  const isMob = useMediaQuery({ maxWidth: 1024 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [featuredCampaign, setFeaturedCampaign] = useState(null);
  const [totalDonation, setTotalDonation] = useState(null);
  const [recentDonator, setRecentDonator] = useState(null);
  const [totalDonator, setTotalDonator] = useState(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const currentYear = new Date().getFullYear();
  const firstDate = new Date(currentYear, 0, 1);
  const lastDate = new Date(currentYear, 11, 31);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    axiosInstance
      .get(
        `customer/berbagi/reports?startDate=${formatDate(
          firstDate
        )}&endDate=${formatDate(lastDate)}`
      )
      .then((res) => {
        const totalDonations = res.data.data.totalDonations;
        setTotalDonation(totalDonations);
      });

    axiosInstance
      .get(
        `customer/berbagi/donations?startDate=${formatDate(
          firstDate
        )}&endDate=${formatDate(lastDate)}`
      )
      .then((res) => {
        const totalDonator = res.data.meta.totalData;
        setTotalDonator(totalDonator);
      });

    axiosInstance
      .get(
        `customer/berbagi/donations?page=1&perPage=4&startDate=${formatDate(
          firstDate
        )}&endDate=${formatDate(lastDate)}`
      )
      .then((res) => {
        const recentDonators = res.data.data;
        setRecentDonator(recentDonators);
      });

    axiosInstance
      .get(`/customer/ecommerce/products?type=DONATION&isFeatured=true`)
      .then((res) => {
        const featuredCampaigns = res.data.data
        setFeaturedCampaign(featuredCampaigns)
      })
  }, []);

  useEffect(() => {
    if (
      totalDonation !== null &&
      recentDonator !== null &&
      totalDonator !== null
    ) {
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [totalDonation, JSON.stringify(recentDonator), totalDonator]);

  React.useEffect(() => {
    if (isMob) {
      setisMobile(isMob);
    } else {
      setisMobile(false);
    }
  }, [isMob]);

  return (
    <>
      {isMob ? (
        <Spin spinning={isLoading}>
          <Grid
            style={{
              width: "100%",
            }}
          >
            <Grid style={{padding: "26px 0px", backgroundColor: 'rgba(94, 217, 158, 0.3)'}}>
            <Typography 
              style={{fontWeight: 700, 
              fontSize: 16, 
              color: "#FAFAFA", 
              padding: "0px 15px"}}
            >
            Penggalangan Dana Mendesak
            </Typography>
            <div style={{marginTop: '22px'}}>
            <ItemsCarousel
              numberOfCards={1}
              gutter={16}
              activePosition="left"
              slidesToScroll={1}
              chevronWidth={60}
              outsideChevron={false}
              showSlither={true}
              firstAndLastGutter={true}
              activeItemIndex={activeItemIndex}
              requestToChangeActive={setActiveItemIndex}
              rightChevron={<NextMobButton/>}
              leftChevron={<PrevMobButton/>}
            >
              {featuredCampaign?.map((data, index) => (
                <Box key={index}>
                  <CardCampaign data={data} isBanner={false}/>
                </Box>
              ))}  
            </ItemsCarousel>
            </div>
          </Grid>
            <Grid>
              <Typography
                style={{
                  fontWeight: 700,
                  color: "#FAFAFA",
                  fontSize: 20,
                  marginBottom: 3,
                  // whiteSpace: "nowrap",
                  padding: "0px 8px",
                }}
              >
                Yuk, kita raih surga dengan berinfak!
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: 9,
                  color: "#FAFAFA",
                  padding: "0px 8px",
                  marginBottom: 10,
                }}
              >
                Alhamdulillah, sekarang berinfak ataupun donasi ngga perlu ribet
                loh! Sudah ada platform infakonline.id yang memudahkan kita
                semua untuk berinfak ataupun membayar zakat. Dimana dan kapan
                saja, in syaa Allah anda lebih mudah untuk segera menambah
                nominal investasi akhirat anda melalui platform infakonline.id
              </Typography>
              <span
                onClick={() => router.push("/TentangKami")}
                style={{
                  marginLeft: 7,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 9,
                  color: "#FAFAFA",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  border: "1px solid #FAFAFA",
                  padding: "6px 15px",
                  borderRadius: 4,
                  textTransform: "inherit",
                }}
              >
                Selengkapnya
              </span>
              <Grid
                style={{
                  background: "white",
                  borderRadius: 8,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                  marginTop: 18,
                  marginBottom: 42,
                }}
              >
                <DonationCard
                  handleCalculate={async (price, id) =>
                    await props.handleCalculate(price, id)
                  }
                  handleSubmit={async (data) => await props.handleSubmit(data)}
                />
              </Grid>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <Grid
                style={{
                  background: "white",
                  borderRadius: 8,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Grid style={{ padding: "7px 9px" }}>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          fontSize: 9,
                          fontWeight: 600,
                          color: "#4d4d4d",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Jumlah Infak dan Zakat Maal Terkumpul Tahun{" "}
                        {new Date().getFullYear()}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 28,
                          fontWeight: 700,
                          paddingLeft: 10,
                        }}
                      >
                        <CurrencyFormat
                          value={totalDonation || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 9,
                          fontWeight: 600,
                          color: "#4d4d4d",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Jumlah Transaksi
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: 28,
                          marginLeft: 9,
                        }}
                      >
                        {totalDonator}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <div
                  style={{
                    border: "1px solid #F5F5F5",
                    width: "100%",
                  }}
                />

                <Grid style={{ padding: "7px 9px" }}>
                  <Grid style={{ display: "flex" }}>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontWeight: 700,
                        fontSize: 10,
                        color: "#4D4D4D",
                        width: "50%",
                        marginBottom: 4,
                      }}
                    >
                      Donatur Terkini
                    </Typography>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      border: "1px solid #2DBE78",
                      borderRadius: 4,
                      marginBottom: 3,
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#2DBE78",
                          color: "#FAFAFA",
                          alignItems: "center",
                          fontSize: 9,
                          fontWeight: 600,
                          padding: "2px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Donatur
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 9,
                          fontWeight: 600,
                          padding: "2px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Via
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 9,
                          fontWeight: 600,
                          padding: "2px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Nominal
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 9,
                          fontWeight: 600,
                          padding: "2px 0px",
                          borderRadius: 2,
                          // textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Waktu Donasi
                      </Typography>
                    </Grid>
                  </Grid>

                  {recentDonator?.map((item) => (
                    <Grid style={{ display: "flex", paddingTop: 5 }}>
                      <Grid item xs={4} style={{ alignSelf: "center" }}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 9,
                            fontWeight: 600,
                            textAlign: "start",
                            paddingLeft: 15,
                          }}
                        >
                          {item.donator}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ alignSelf: "center" }}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 9,
                            fontWeight: 600,
                            textAlign: "start",
                            paddingLeft: 15,
                            textTransform: "uppercase",
                          }}
                        >
                          {item?.paymentChannelName}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ alignSelf: "center" }}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 9,
                            fontWeight: 600,
                            textAlign: "start",
                            paddingLeft: 15,
                          }}
                        >
                          Rp.{" "}
                          {item.total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          {/* <CurrencyFormat
                          value={item.total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        /> */}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ alignSelf: "center" }}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 9,
                            fontWeight: 600,
                            textAlign: "start",
                          }}
                        >
                          {dayjs(new Date(item.paidAt)).format(
                            "DD MM YYYY, HH:mm"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <div
                  style={{
                    border: "1px solid #F5F5F5",
                    width: "100%",
                  }}
                />
                <Typography
                  onClick={() => router.push("/DonaturTerkini")}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    alignItems: "center",
                    color: "#28A96B",
                    padding: "10px 0px",
                    cursor: "pointer",
                  }}
                >
                  Lihat semua
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid style={{ marginRight: 60 }} /> */}
          </Grid>
        </Spin>
      ) : (
        <Spin spinning={isLoading}>
          {/* Desktop */}
          <Grid style={{padding: "26px 0px", backgroundColor: 'rgba(94, 217, 158, 0.3)'}}>
            <Typography 
              style={{fontWeight: 700, 
              fontSize: 28, 
              color: "#FAFAFA", 
              padding: "0px 80px"}}
            >
            Penggalangan Dana Mendesak
            </Typography>
            <div style={{marginTop: '52px'}}>
            <ItemsCarousel
              numberOfCards={2}
              gutter={40}
              activePosition="center"
              slidesToScroll={1}
              chevronWidth={60}
              outsideChevron={false}
              showSlither={true}
              firstAndLastGutter={true}
              activeItemIndex={activeItemIndex}
              requestToChangeActive={setActiveItemIndex}
              rightChevron={<NextButton/>}
              leftChevron={<PrevButton/>}
            >
              {featuredCampaign?.map((data, index) => (
                <Box key={index}>
                  <CardCampaign data={data} isBanner={false}/>
                </Box>
              ))}  
            </ItemsCarousel>
            </div>
          </Grid>
          <Grid
            style={{
              paddingTop: 35,
              display: "flex",
              // paddingLeft: 187,
              // paddingRight: 187,
              paddingLeft: 106,
              paddingRight: 106,
            }}
          >
            <Grid item xs={7}>
              <Grid
                style={{
                  background: "white",
                  borderRadius: 8,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Grid style={{ padding: "0px 16px", paddingTop: 13 }}>
                  <Grid style={{ display: "flex" }}>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#4d4d4d",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Jumlah Infak dan Zakat Maal Terkumpul Tahun{" "}
                        {new Date().getFullYear()}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 40,
                          fontWeight: 700,
                          marginLeft: 17,
                          marginTop: 8,
                        }}
                      >
                        <CurrencyFormat
                          value={totalDonation || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#4d4d4d",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Jumlah Transaksi
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: 40,
                          marginTop: "8px",
                        }}
                      >
                        {totalDonator}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <div
                  style={{
                    border: "1px solid #F5F5F5",
                    width: "100%",
                    marginTop: 25,
                    marginBottom: 11,
                  }}
                />

                <Grid style={{ padding: "0px 16px" }}>
                  <Grid style={{ display: "flex" }}>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#4D4D4D",
                        width: "50%",
                        marginBottom: 8,
                      }}
                    >
                      Donatur Terkini
                    </Typography>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      border: "1px solid #2DBE78",
                      borderRadius: 4,
                      marginBottom: 5,
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#2DBE78",
                          color: "#FAFAFA",
                          alignItems: "center",
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "7px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Donatur
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "7px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Via
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "7px 18px",
                          borderRadius: 2,
                          // textAlign: "center",
                        }}
                      >
                        Nominal
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        style={{
                          background: "#FAFAFA",
                          color: "#2DBE78",
                          alignItems: "center",
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "7px 0px",
                          borderRadius: 2,
                          // textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Waktu Donasi
                      </Typography>
                    </Grid>
                  </Grid>

                  {recentDonator?.map((item) => (
                    <Grid style={{ display: "flex", paddingTop: 8 }}>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: "0px 18px",
                            textAlign: "start",
                          }}
                        >
                          {item?.donator}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: "0px 18px",
                            textAlign: "start",
                            textTransform: "uppercase",
                          }}
                        >
                          {item?.paymentChannelName}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: "0px 18px",
                            textAlign: "start",
                          }}
                        >
                          Rp.{" "}
                          {item?.total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            background: "#FFFFF",
                            color: "#4D4D4D",
                            fontSize: 12,
                            fontWeight: 600,
                            // padding: "0px 18px",
                            textAlign: "start",
                          }}
                        >
                          {dayjs(new Date(item.paidAt)).format(
                            "DD MMMM YYYY, HH:mm"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <div
                  style={{
                    border: "1px solid #F5F5F5",
                    width: "100%",
                    marginTop: 15,
                  }}
                />
                <Typography
                  className="cursor-pointer"
                  onClick={() => router.push("/DonaturTerkini")}
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    alignItems: "center",
                    color: "#28A96B",
                    padding: 15,
                    cursor: "pointer",
                  }}
                >
                  Lihat semua
                </Typography>
              </Grid>
              <Typography
                style={{
                  fontWeight: 700,
                  color: "#FAFAFA",
                  fontSize: 35,
                  marginTop: 23,
                  marginBottom: 3,
                  // whiteSpace: "nowrap",
                }}
              >
                Yuk, kita raih surga dengan berinfak!
              </Typography>
              <Typography
                onClick={() => router.push("/DonaturTerkini")}
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FAFAFA",
                  marginRight: 20,
                  marginBottom: 20,
                }}
              >
                Alhamdulillah, sekarang berinfak ataupun donasi ngga perlu ribet
                loh! Sudah ada platform infakonline.id yang memudahkan kita
                semua untuk berinfak ataupun membayar zakat. Dimana dan kapan
                saja, in syaa Allah anda lebih mudah untuk segera menambah
                nominal investasi akhirat anda melalui platform infakonline.id
              </Typography>
              <span
                onClick={() => router.push("/TentangKami")}
                style={{
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FAFAFA",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  border: "1px solid #FAFAFA",
                  padding: "6px 15px",
                  borderRadius: 4,
                  textTransform: "inherit",
                }}
              >
                Selengkapnya
              </span>
            </Grid>

            {/* <Grid item xs={1} style={{ marginRight: 60 }} /> */}
            <Grid
              item
              xs={5}
              style={{
                background: "white",
                padding: 28,
                borderRadius: 8,
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                marginLeft: 50,
                minHeight: 600,
              }}
            >
              <DonationCard
                handleCalculate={async (price, id) =>
                  await props.handleCalculate(price, id)
                }
                handleSubmit={async (data) => await props.handleSubmit(data)}
              />
            </Grid>
          </Grid>
        </Spin>
      )}
    </>
  );
}

export default BannerSectionOne;
