const NextIconButton = ({className, ...props}) => {
    return (
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
        <g filter="url(#filter0_d_6419_14190)">
        <circle cx="31" cy="31" r="29" fill="#2DBE78"/>
        </g>
        <path d="M26.293 21.7426C26.1308 21.9045 26.0021 22.0968 25.9143 22.3085C25.8265 22.5202 25.7812 22.7472 25.7812 22.9763C25.7812 23.2055 25.8265 23.4325 25.9143 23.6442C26.0021 23.8559 26.1308 24.0482 26.293 24.2101L33.083 31.0001L26.293 37.7901C25.9658 38.1173 25.782 38.5611 25.782 39.0238C25.782 39.4866 25.9658 39.9304 26.293 40.2576C26.6202 40.5848 27.064 40.7686 27.5268 40.7686C27.9895 40.7686 28.4333 40.5848 28.7605 40.2576L36.793 32.2251C36.9553 32.0632 37.084 31.8709 37.1718 31.6592C37.2596 31.4475 37.3048 31.2205 37.3048 30.9914C37.3048 30.7622 37.2596 30.5352 37.1718 30.3235C37.084 30.1118 36.9553 29.9195 36.793 29.7576L28.7605 21.7251C28.0955 21.0601 26.9755 21.0601 26.293 21.7426Z" fill="white"/>
        <defs>
        <filter id="filter0_d_6419_14190" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="6" dy="6"/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.159735 0 0 0 0 0.138898 0 0 0 0 0.138898 0 0 0 0.09 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6419_14190"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6419_14190" result="shape"/>
        </filter>
        </defs>
        </svg>

    )
}

export default NextIconButton