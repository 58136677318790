import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useMediaQuery } from "react-responsive";
import Checkbox from "@material-ui/core/Checkbox";
import PaymentMethod from "./PaymentMethod";
import Radio from "@material-ui/core/Radio";
import { getListProducts } from "../../services/getListProduct";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import MyPhoneInput, { CountryPhoneInputValue } from "../PhoneInput/PhoneInput";
import MyPhoneInput, { CountryPhoneInput } from "../PhoneInput/PhoneInput";
import CalculatorZakat from "./CalculatorZakat";
import IconCalculator from "../../public/Icon/calculatorIcon";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: "#4D4D4D",
    "&$checked": {
      color: "#4D4D4D",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "#2DBE78",
    "&$checked": {
      color: "#2DBE78",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

function DonationCard(props) {
  const { register, handleSubmit, control, errors, setValue, getValues } =
    useForm();
  const [isMobile, setisMobile] = useState(false);
  const isMob = useMediaQuery({ maxWidth: 1024 });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadPaymentChannel, setIsLoadPaymentChannel] = useState(false);
  const [npwp, setNpwp] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [availablePayments, setAvailablePayments] = useState();
  const [isHambaAllah, setIsHambaAllah] = useState(false);
  const [isPotongPajak, setIsPotongPajak] = useState(false);
  const [cardDisplay, setCardDisplay] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(
    process.env.INFAK_ID
  );
  const [donorType, setDonorType] = useState("INDIVIDUAL");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customPrice, setCustomPrice] = useState(0);
  const [isValidPrice, setIsValidPrice] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [timeRender, setTimeRender] = useState(0);
  const [inputPhoneValue, setInputPhoneValue] = useState();
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (isMob) {
      setisMobile(isMob);
    } else {
      setisMobile(false);
    }
    const data = JSON.parse(localStorage.getItem("savedUser"));
    if (data) {
      setName(data.name);
      setInputPhoneValue(data.phone);
      setEmail(data.email);
      setValue("phone", data.phone, { shouldValidate: false });
      setValue("name", data.name, { shouldValidate: false });
      setValue("email", data.email, { shouldValidate: false });
    }
  }, [isMob]);

  useEffect(() => {
    async function getData() {
      const { data } = await getListProducts();
      setProducts(data);
      setSelectedProduct(data[0]);
    }
    getData();
  }, []);

  useEffect(() => {
    let data = {
      name: name,
      phone: inputPhoneValue,
      email: email,
    };
    if (!first) {
      localStorage.setItem("savedUser", JSON.stringify(data));
    }
  }, [name, inputPhoneValue, email]);

  useEffect(() => {
    // setTimeRender(timeRender + 1);
    if (timeRender > 2) {
      phone.length > 6 ? setIsValidPhone(true) : setIsValidPhone(false);
    }
  }, [phone]);

  const handleIsHambaAllah = (event) => {
    setIsHambaAllah(event.target.checked);
  };

  const handleIsPotongPajak = (event) => {
    setIsPotongPajak(event.target.checked);
  };

  const handleChangeProduct = (event) => {
    setSelectedProductId(event.target.value);
  };

  const handleChangeType = (event) => {
    setDonorType(event.target.value);
  };

  const handleFocusOutCustomPrice = (event) => {
    const formattedCurrency = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(customPrice);

    calculateDonation(event.target.value);
    event.target.value = formattedCurrency;
  };

  const calculateDonation = async (nominal) => {
    const price = nominal ? nominal : customPrice;

    if (price >= 1000) {
      setIsLoadPaymentChannel(true);

      const res = await props.handleCalculate(price, selectedProductId);

      if (res) {
        setIsLoadPaymentChannel(false);
        setAvailablePayments(res.data.data.availablePayments);
        if (selectedPaymentMethod) {
          const isAvailablePayment = res.data.data.availablePayments.find(
            (item) => item.id === selectedPaymentMethod.id
          );
          if (
            !isAvailablePayment?.minimumTransaction ||
            Number(price) < isAvailablePayment?.minimumTransaction
          ) {
            setValue("selectedPaymentId", null, {
              shouldValidate: true,
            });
            setSelectedPaymentMethod(null);
          }
        }
      } else {
        setIsLoadPaymentChannel(false);
      }
    }
  };

  const handleOnChangeName = (event) => {
    const inputName = event.target.value;
    inputName.length > 3 ? setIsValidName(true) : setIsValidName(false);
    setName(inputName);
  };

  const handleOnChangePhone = (p) => {
    // const inputPhone = event.target.value;
    // inputPhone.length > 6 ? setIsValidPhone(true) : setIsValidPhone(false);
    setPhone(p);
  };

  const handleChangeCustomPrice = (event) => {
    const nominal = event.target.value;
    nominal > 1000 ? setIsValidPrice(true) : setIsValidPrice(false);
    setCustomPrice(nominal);
  };

  const onSubmit = async (data) => {
    if (selectedProduct) {
      if (
        Number(customPrice) <
        selectedProduct.customPriceConfig.minimumCustomPrice
      ) {
        setIsValidPrice(false);
      }
    }
    const phoneNumber =
      inputPhoneValue.phone.slice(0, 2) === "08"
        ? inputPhoneValue.code.toString() +
          inputPhoneValue.phone.slice(1, inputPhoneValue.phone.length)
        : inputPhoneValue.phone.slice(0, 2) === "62"
        ? inputPhoneValue.phone
        : inputPhoneValue.code.toString() + inputPhoneValue.phone;

    const dataDonation = {
      ...(data.productId === process.env.ZAKAT_ID
        ? {
            items: [
              {
                productId: data.productId,
                quantity: 1,
                customPrice: Number(customPrice),
              },
            ],
          }
        : {
            item: {
              productId: data.productId,
              quantity: 1,
              customPrice: Number(customPrice),
            },
          }),

      contact: {
        phone: phoneNumber,
        email: data.email || "info@infakonline.id",
      },
      shipping: null,
      billing: {
        name: data.name,
        phone: phoneNumber,
        email: data.email || "info@infakonline.id",
        address: "",
        postcode: "",
        country: "",
        latitude: 0,
        longitude: 0,
        note: "",
        province: "",
        cityId: "",
        city: "",
      },
      voucherCode: "",
      customerNote: "",
      paymentChannelId: data.selectedPaymentId,
      isAnonymousDonor: isHambaAllah,
      metadata: {
        isPotongPajak: isPotongPajak,
        npwp: npwp || null,
        donorType:
          selectedProductId === process.env.INFAK_ID ? donorType : "INDIVIDUAL",
      },
    };

    if (customPrice >= 1000) {
      setIsLoading(true);

      const res = await props.handleSubmit(dataDonation);

      if (res) {
        window.location.href = res.data.data.payments[0].url;
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleChanged = (event) => {
    const nominal = Number(event.target.value);
    nominal > 1000 ? setIsValidPrice(true) : setIsValidPrice(false);
    setCustomPrice(nominal);
  };

  useEffect(() => {
    if (!first) {
      setValue("nominal", customPrice, { shouldValidate: true });
      setValue("phone", inputPhoneValue, { shouldValidate: true });
      setValue("name", name, { shouldValidate: true });
      setValue("email", email, { shouldValidate: true });
      setValue("selectedPaymentId", selectedPaymentMethod?.id, {
        shouldValidate: true,
      });
    }
    setFirst(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [cardDisplay]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (customPrice) {
        calculateDonation();
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [customPrice]);

  if (cardDisplay === "donationMethod") {
    return (
      <PaymentMethod
        availablePayments={availablePayments}
        handleSelectedPayment={(value) => setSelectedPaymentMethod(value)}
        setDisplayCard={() => setCardDisplay("")}
      />
    );
  }

  if (cardDisplay === "CalculatorZakat") {
    return (
      <CalculatorZakat
        setDisplayCard={() => setCardDisplay("")}
        handleZakat={(value) => {
          setCardDisplay("");
          setSelectedProductId(process.env.ZAKAT_ID);
          if (value >= 1000) {
            setCustomPrice(value);
            calculateDonation(value);
            setValue("nominal", value, { shouldValidate: true });
          }
        }}
      />
    );
  }

  return (
    <>
      {/* Loading */}
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          position: "fixed",
          right: "0",
          top: "0",
          zIndex: "50",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          display: `${isLoading ? "flex" : "none"}`,
        }}
      >
        <CircularProgress style={{ color: "#ffffff" }} />
      </div>
      {isMobile ? (
        <>
          <form
            id="form-donation"
            onSubmit={handleSubmit(onSubmit)}
            style={{ padding: "0px 25px", paddingTop: 18, paddingBottom: 10 }}
          >
            <Typography
              style={{ fontWeight: 700, fontSize: 12, color: 666666 }}
            >
              Pilihan Donasi
            </Typography>
            <Grid
              onClick={() => setCardDisplay("donationType")}
              style={{
                display: "flex",
                padding: "12px 5px",
                marginBottom: 6,
                cursor: "pointer",
                width: "100%",
                justifyContent: "start",
              }}
            >
              <Grid
                item
                // key={item.id}
                xs={6}
                style={{ alignSelf: "center" }}
              >
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <GreenRadio
                    inputRef={register}
                    ref={register}
                    checked={selectedProductId === process.env.INFAK_ID}
                    onChange={handleChangeProduct}
                    value={process.env.INFAK_ID}
                    name="productId"
                    inputProps={{ "aria-label": "A" }}
                    style={{ padding: 0, paddingRight: 9 }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "uppercase",
                    }}
                  >
                    INFAK
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                // key={item.id}
                xs={6}
                style={{ alignSelf: "center" }}
              >
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <GreenRadio
                    inputRef={register}
                    ref={register}
                    checked={selectedProductId === process.env.ZAKAT_ID}
                    onChange={handleChangeProduct}
                    value={process.env.ZAKAT_ID}
                    name="productId"
                    inputProps={{ "aria-label": "B" }}
                    style={{ padding: 0, paddingRight: 9 }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "uppercase",
                    }}
                  >
                    ZAKAT MAAL
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {selectedProductId === process.env.INFAK_ID && (
              <div
                style={{
                  display: "grid",
                  gridGap: 10,
                  gridTemplateColumns: "auto auto auto",
                }}
              >
                <Button
                  onClick={() => {
                    setCustomPrice(5000);
                    calculateDonation(5000);
                    setValue("nominal", 5000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 5.000
                  </Typography>
                </Button>

                <Button
                  onClick={() => {
                    setCustomPrice(10000);
                    calculateDonation(10000);
                    setValue("nominal", 10000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 10.000
                  </Typography>
                </Button>

                <Button
                  onClick={() => {
                    setCustomPrice(25000);
                    calculateDonation(25000);
                    setValue("nominal", 25000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 25.000
                  </Typography>
                </Button>

                <Button
                  onClick={() => {
                    setCustomPrice(50000);
                    calculateDonation(50000);
                    setValue("nominal", 50000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 50.000
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    setCustomPrice(100000);
                    calculateDonation(100000);
                    setValue("nominal", 100000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 100.000
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    setCustomPrice(500000);
                    calculateDonation(500000);
                    setValue("nominal", 500000, { shouldValidate: true });
                  }}
                  style={{
                    padding: "3px 0px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 2,
                    width: "100%",
                    textTransform: "inherit",
                    display: "inherit",
                    alignItems: "none",
                    justifyContent: "none",
                  }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#4D4D4D",
                    }}
                  >
                    Rp 500.000
                  </Typography>
                </Button>
              </div>
            )}

            {selectedProductId === process.env.ZAKAT_ID && (
              <React.Fragment>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: "#4D4D4D",
                    marginTop: 20,
                  }}
                >
                  Bingung menghitung zakat ?
                </Typography>
                <div
                  style={{
                    backgroundColor: "#2DBE78",
                    border: "1px solid #CCCCCC",
                    borderRadius: 5,
                    padding: 12,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => setCardDisplay("CalculatorZakat")}
                >
                  <div style={{ display: "flex" }}>
                    <IconCalculator />
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#FFFFFF",
                        marginLeft: 5,
                      }}
                    >
                      Kalkulator Zakat
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon
                    style={{ color: "white" }}
                    fontSize="small"
                  />
                </div>
              </React.Fragment>
            )}

            <Grid
              style={{
                padding: "8px 16px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                marginTop: 12,
              }}
            >
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  item
                  xs={6}
                  style={{
                    fontWeight: 700,
                    fontSize: 12,
                    color: "#4D4D4D",
                  }}
                >
                  Nominal donasi
                </Typography>
              </Grid>

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#F5F5F5",
                  padding: "7px 11px",
                  borderRadius: 2,
                  marginTop: 6,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: "#4D4D4D",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  Rp
                </Typography>
                <Controller
                  control={control}
                  defaultValue=""
                  name="nominal"
                  rules={{ required: true, min: 1000 }}
                  render={({ onChange, onBlur }) => (
                    <NumberFormat
                      thousandSeparator={true}
                      style={{
                        border: "none",
                        textAlign: "right",
                        backgroundColor: "inherit",
                        fontWeight: "700",
                      }}
                      value={customPrice}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;

                        setCustomPrice(value);
                      }}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        onBlur(e);
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}></span> */}

                {/* <input
                    name="nominal"
                    ref={register}
                    id="manig"
                    type="text"
                    value={customPrice.toString()}
                    onBlur={(event) => {
                      handleFocusOutCustomPrice(event);
                    }}
                    onChange={(event) => {
                      handleChangeCustomPrice(event);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      classes: classes,
                    }}
                  /> */}
                {/* {!isValidPrice && `Nominal tidak boleh kurang dari Rp.1000 !`} */}
              </div>
              {errors.nominal?.type === "required" && (
                <div style={{ color: "red" }}>nominal harus diisi!</div>
              )}
              {errors.nominal?.type === "min" && (
                <div style={{ color: "red" }}>
                  Nominal minimal Rp.1000 untuk e-wallet
                </div>
              )}
            </Grid>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 12,
                color: 666666,
                marginTop: 12,
              }}
            >
              Metode Pembayaran
            </Typography>
            <Button
              disabled={!availablePayments}
              onClick={() => setCardDisplay("donationMethod")}
              style={{
                display: "flex",
                padding: 0,
                paddingTop: 5,
                paddingBottom: 18,
                cursor: "pointer",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={9}
                style={{ alignItems: "center", display: "flex" }}
              >
                {selectedPaymentMethod && !isLoadPaymentChannel && (
                  <img
                    style={{ width: "3rem" }}
                    src={selectedPaymentMethod.imageUrl}
                    height="inherit"
                  />
                )}
                {isLoadPaymentChannel ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      style={{
                        color: "#2DBE78",
                        width: "20px",
                        height: "inherit",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: 5,
                        textTransform: "initial",
                        // whiteSpace: "nowrap",
                        fontSize: "0.7rem",
                        color: "black",
                        textAlign: "left",
                      }}
                    >
                      Sedang mengambil metode pembayaran...
                    </span>
                  </div>
                ) : availablePayments ? (
                  <Typography
                    style={{
                      marginLeft: "0.5rem",
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {!selectedPaymentMethod
                      ? "Pilih metode pembayaran"
                      : selectedPaymentMethod.name}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      marginLeft: "1rem",
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Masukkan nominal terlebih dahulu!
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={3}
                style={{ textAlign: "right", alignSelf: "center" }}
                onClick={() => setCardDisplay("donationMethod")}
              >
                {selectedPaymentMethod ? (
                  <Button
                    style={{
                      padding: "2px 12px",
                      background: "#35CF85",
                      borderRadius: 20,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "rgba(250, 250, 250, 1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Ganti
                    </Typography>
                    <KeyboardArrowDownRoundedIcon
                      style={{
                        color: "rgba(250, 250, 250, 1)",
                        marginLeft: 3,
                      }}
                    />
                  </Button>
                ) : (
                  <ChevronRightRoundedIcon
                    style={{ color: "#CCCCCC", verticalAlign: "top" }}
                  />
                )}
              </Grid>
            </Button>
            <input
              ref={register({ required: true })}
              name="selectedPaymentId"
              type="hidden"
              value={selectedPaymentMethod ? selectedPaymentMethod.id : ""}
            />
            {errors.selectedPaymentId && (
              <p style={{ color: "red" }}>
                Silahkan pilih metode pembayarannya
              </p>
            )}
            {selectedProductId === process.env.INFAK_ID && (
              <React.Fragment>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 12,
                    color: 666666,
                    marginTop: 12,
                  }}
                >
                  Tipe Donatur
                </Typography>
                <Grid
                  onClick={() => setCardDisplay("donationType")}
                  style={{
                    display: "flex",
                    padding: "12px 5px",
                    marginBottom: 6,
                    cursor: "pointer",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <Grid item xs={6} style={{ alignSelf: "center" }}>
                    <Grid style={{ display: "flex", alignItems: "center" }}>
                      <GreenRadio
                        checked={donorType === "INDIVIDUAL"}
                        onChange={handleChangeType}
                        value={"INDIVIDUAL"}
                        name="type"
                        inputProps={{ "aria-label": "A" }}
                        style={{ padding: 0, paddingRight: 9 }}
                      />
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          textTransform: "uppercase",
                        }}
                      >
                        Personal
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} style={{ alignSelf: "center" }}>
                    <Grid style={{ display: "flex", alignItems: "center" }}>
                      <GreenRadio
                        checked={donorType === "BUSINESS"}
                        onChange={handleChangeType}
                        value={"BUSINESS"}
                        name="type"
                        inputProps={{ "aria-label": "B" }}
                        style={{ padding: 0, paddingRight: 9 }}
                      />
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          textTransform: "uppercase",
                        }}
                      >
                        Kelompok/ Perusahaan
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            <Controller
              control={control}
              defaultValue=""
              name="name"
              rules={{ required: true, minLength: 3 }}
              render={({ onChange }) => (
                <input
                  value={name}
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    setName(e.target.value);
                    setValue("name", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  style={{
                    padding: "14px 12px",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #CCCCCC",
                    outline: "none",
                    width: "100%",
                    textAlign: "start",
                    borderRadius: 2,
                    marginTop: 14,
                  }}
                  placeholder={"Masukkan Nama Lengkap"}
                />
              )}
            />

            {errors.name?.type === "required" && (
              <span style={{ color: "red" }}>
                Nama mohon diisi. Bisa tidak ditampilkan
              </span>
            )}
            {errors.name?.type === "minLength" && (
              <span style={{ color: "red" }}>
                Nama harus lebih dari 3 karakter
              </span>
            )}

            <Controller
              control={control}
              defaultValue=""
              name="phone"
              countryCodeEditable={false}
              rules={{ required: true, minLength: 8 }}
              render={({ onChange }) => (
                <MyPhoneInput
                  value={inputPhoneValue && inputPhoneValue.phone}
                  values={inputPhoneValue}
                  style={{ marginTop: 12, backgroundColor: "#f5f5f5" }}
                  placeholder="Enter phone number"
                  onChange={(v) => {
                    onChange(v);
                    setValue("phone", v, { shouldValidate: true });
                    setInputPhoneValue(v);
                  }}
                />
              )}
            />
            {/* <PhoneInput
                    value={phone}
                    inputProps={{ name: "phone" }}
                    countryCodeEditable={false}
                    country={"id"}
                    onChange={(p) => {
                      onChange(p);
                      setPhone(p);
                    }}
                    style={{
                      marginTop: 12,
                      fontWeight: 400,
                    }}
                    placeholder="Masukkan Nomor Ponsel"
                  /> */}
            {errors.phone?.type === "required" && (
              <span style={{ color: "red" }}>
                Nomor ponsel mohon diisi. Tidak akan ditampilkan
              </span>
            )}
            {inputPhoneValue && inputPhoneValue?.phone?.length < 6 && (
              <span style={{ color: "red" }}>
                Nomor ponsel harus lebih dari 6 karakter
              </span>
            )}
            <div
              style={{
                fontWeight: 700,
                fontSize: 12,
                color: 666666,
                margin: "12px 0px 3px 0px",
              }}
            >
              Email (Opsional)
            </div>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              rules={{
                // required: true,
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "invalid email address",
                },
              }}
              render={({ onChange }) => (
                <input
                  value={email}
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    setEmail(e.target.value);
                    setValue("email", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  style={{
                    padding: "14px 12px",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #CCCCCC",
                    outline: "none",
                    width: "100%",
                    textAlign: "start",
                    borderRadius: 2,
                    marginTop: 14,
                  }}
                  placeholder={"Masukkan Email (Opsional)"}
                />
              )}
            />

            {email && errors?.email?.message && (
              <span style={{ color: "red" }}>
                Mohon input email yang valid!
              </span>
            )}

            {selectedProductId === process.env.ZAKAT_ID && (
              <React.Fragment>
                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    marginTop: 14,
                  }}
                >
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <GreenCheckbox
                        checked={isPotongPajak}
                        onChange={handleIsPotongPajak}
                        name="isPotongPajak"
                        ref={register}
                        // value={true}
                        fontSize="small"
                        style={{ padding: 0 }}
                      />
                    }
                  />
                  <Typography
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      color: "#4D4D4D",
                      marginLeft: 8,
                    }}
                  >
                    Potong Pajak
                  </Typography>
                </Grid>
                {isPotongPajak && (
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                      marginTop: 14,
                    }}
                  >
                    <Controller
                      control={control}
                      defaultValue=""
                      name="npwp"
                      rules={{ required: true }}
                      render={({ onChange }) => (
                        <input
                          value={npwp}
                          type="text"
                          onChange={(e) => {
                            onChange(e);
                            setNpwp(e.target.value);
                            setValue("npwp", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          style={{
                            padding: "14px 12px",
                            fontSize: 14,
                            fontWeight: 400,
                            backgroundColor: "#F5F5F5",
                            border: "1px solid #CCCCCC",
                            outline: "none",
                            width: "100%",
                            textAlign: "start",
                            borderRadius: 2,
                          }}
                          placeholder={"NPWP"}
                        />
                      )}
                    />

                    {errors.npwp?.type === "required" && (
                      <span style={{ color: "red" }}>NPWP harus diisi!</span>
                    )}
                  </Grid>
                )}
              </React.Fragment>
            )}

            <Grid
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginTop: 14,
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <GreenCheckbox
                    checked={isHambaAllah}
                    onChange={handleIsHambaAllah}
                    name="isHambaAllah"
                    ref={register}
                    // value={true}
                    fontSize="small"
                    style={{ padding: 0 }}
                  />
                }
              />
              <Typography
                style={{
                  fontSize: 11,
                  fontWeight: 700,
                  color: "#4D4D4D",
                  marginLeft: 8,
                }}
              >
                Donasi sebagai Hamba Allah
              </Typography>
            </Grid>
            <Button
              type="submit"
              form="form-donation"
              style={{
                backgroundColor: customPrice < 1 ? "#B8B8B8" : "#2DBE78",
                // backgroundColor: "#B8B8B8",
                marginTop: 22,
                marginBottom: 16,
                width: "100%",
                color: "#FAFAFA",
                padding: 12,
                textTransform: "inherit",
                fontWeight: 600,
              }}
            >
              Bismillah Donasi Sekarang
            </Button>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "rgb(109 109 109)",
                marginBottom: 10,
              }}
            >
              Dana yang didonasikan melalui infakonline.id bukan bersumber dan
              bukan untuk tujuan sesuatu yang dilarang Allah seperti terorisme,
              korupsi, pencucian uang (money laundry), dll.
            </Typography>
          </form>
        </>
      ) : (
        <Grid>
          <>
            <form id="form-donation" onSubmit={handleSubmit(onSubmit)}>
              <Typography
                style={{ fontWeight: 700, fontSize: 12, color: 666666 }}
              >
                Pilihan Donasi
              </Typography>
              <Grid
                onClick={() => setCardDisplay("donationType")}
                style={{
                  display: "flex",
                  padding: "12px 5px",
                  marginBottom: 6,
                  cursor: "pointer",
                  width: "100%",
                  justifyContent: "start",
                }}
              >
                <Grid item xs={6} style={{ alignSelf: "center" }}>
                  <Grid style={{ display: "flex", alignItems: "center" }}>
                    <GreenRadio
                      // type="radio"
                      inputRef={register}
                      ref={register}
                      checked={selectedProductId === process.env.INFAK_ID}
                      onChange={handleChangeProduct}
                      value={process.env.INFAK_ID}
                      name="productId"
                      inputProps={{ "aria-label": "A" }}
                      style={{ padding: 0, paddingRight: 9 }}
                    />
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        textTransform: "uppercase",
                      }}
                    >
                      INFAK
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  // key={item.id}
                  xs={6}
                  style={{ alignSelf: "center" }}
                >
                  <Grid style={{ display: "flex", alignItems: "center" }}>
                    <GreenRadio
                      inputRef={register}
                      ref={register}
                      checked={selectedProductId === process.env.ZAKAT_ID}
                      onChange={handleChangeProduct}
                      value={process.env.ZAKAT_ID}
                      name="productId"
                      inputProps={{ "aria-label": "B" }}
                      style={{ padding: 0, paddingRight: 9 }}
                    />
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        textTransform: "uppercase",
                      }}
                    >
                      ZAKAT MAAL
                    </Typography>
                  </Grid>
                </Grid>
                {/* </> */}
                {/* ))} */}
              </Grid>
              {selectedProductId === process.env.INFAK_ID && (
                <div
                  style={{
                    display: "grid",
                    gridGap: 5,
                    gridTemplateColumns: "auto auto auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setCustomPrice(5000);
                      calculateDonation(5000);
                      setValue("nominal", 5000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 5,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 5.000
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      setCustomPrice(10000);
                      calculateDonation(10000);
                      setValue("nominal", 100000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 5,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 10.000
                    </Typography>
                  </Button>

                  <Button
                    onClick={() => {
                      setCustomPrice(25000);
                      calculateDonation(25000);
                      setValue("nominal", 25000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 5,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 25.000
                    </Typography>
                  </Button>

                  <Button
                    onClick={() => {
                      setCustomPrice(50000);
                      calculateDonation(50000);
                      setValue("nominal", 50000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 5,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 50.000
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      setCustomPrice(100000);
                      calculateDonation(100000);
                      setValue("nominal", 100000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 0,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 100.000
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      setCustomPrice(500000);
                      calculateDonation(500000);
                      setValue("nominal", 500000, { shouldValidate: true });
                    }}
                    style={{
                      padding: "3px 3px",
                      border: "1px solid #CCCCCC",
                      borderRadius: 2,
                      width: "100%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      textTransform: "inherit",
                      display: "inherit",
                      alignItems: "none",
                      justifyContent: "none",
                      marginRight: 0,
                    }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      Rp 500.000
                    </Typography>
                  </Button>
                </div>
              )}

              {selectedProductId === process.env.ZAKAT_ID && (
                <React.Fragment>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: 12,
                      color: "#4D4D4D",
                      marginTop: 20,
                    }}
                  >
                    Bingung menghitung zakat ?
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "#2DBE78",
                      border: "1px solid #CCCCCC",
                      borderRadius: 5,
                      padding: 12,
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => setCardDisplay("CalculatorZakat")}
                  >
                    <div style={{ display: "flex" }}>
                      <IconCalculator />
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#FFFFFF",
                          marginLeft: 5,
                        }}
                      >
                        Kalkulator Zakat
                      </Typography>
                    </div>
                    <ArrowForwardIosIcon
                      style={{ color: "white" }}
                      fontSize="small"
                    />
                  </div>
                </React.Fragment>
              )}

              <Grid
                style={{
                  padding: "8px 16px",
                  border: "1px solid #CCCCCC",
                  borderRadius: 2,
                  marginTop: 12,
                }}
              >
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 700,
                      fontSize: 12,
                      color: "#4D4D4D",
                    }}
                  >
                    Nominal donasi
                  </Typography>
                </Grid>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#F5F5F5",
                    padding: "7px 11px",
                    borderRadius: 2,
                    marginTop: 6,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#4D4D4D",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    Rp
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="nominal"
                    rules={{ required: true, min: 1000 }}
                    render={({ onChange, onBlur }) => (
                      <NumberFormat
                        thousandSeparator={true}
                        style={{
                          border: "none",
                          textAlign: "right",
                          backgroundColor: "inherit",
                          fontWeight: "700",
                        }}
                        value={customPrice}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;

                          setCustomPrice(value);
                        }}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={(e) => {
                          onBlur(e);
                        }}
                      />
                    )}
                  />
                </div>
                <span style={{ color: "red" }}></span>
                {errors.nominal?.type === "required" && (
                  <span style={{ color: "red" }}>nominal harus diisi!</span>
                )}
                {errors.nominal?.type === "min" && (
                  <span style={{ color: "red" }}>
                    Nominal minimal Rp.1000 untuk e-wallet
                  </span>
                )}
              </Grid>

              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  color: 666666,
                  marginTop: 12,
                }}
              >
                Metode Pembayaran
              </Typography>

              <Button
                disabled={!availablePayments}
                onClick={() => setCardDisplay("donationMethod")}
                style={{
                  display: "flex",
                  padding: "14px 5px",
                  borderTop: "1px solid #CCCCCC",
                  borderBottom: "1px solid #CCCCCC",
                  marginTop: 11,
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  xs={9}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  {selectedPaymentMethod && !isLoadPaymentChannel && (
                    <img
                      style={{ width: "3rem" }}
                      src={selectedPaymentMethod.imageUrl}
                      height="inherit"
                    />
                  )}
                  {isLoadPaymentChannel ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CircularProgress
                        style={{
                          color: "#2DBE78",
                          width: "20px",
                          height: "inherit",
                        }}
                      />
                      <span
                        style={{
                          textTransform: "initial",
                          whiteSpace: "nowrap",
                          fontSize: "0.7rem",
                          color: "black",
                          marginLeft: 5,
                        }}
                      >
                        Sedang mengambil metode pembayaran...
                      </span>
                    </div>
                  ) : availablePayments ? (
                    <Typography
                      style={{
                        marginLeft: "0.5rem",
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {!selectedPaymentMethod
                        ? "Pilih metode pembayaran"
                        : selectedPaymentMethod.name}
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        marginLeft: "1rem",
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Masukkan nominal terlebih dahulu!
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textAlign: "right", alignSelf: "center" }}
                  onClick={() => setCardDisplay("donationMethod")}
                >
                  {selectedPaymentMethod ? (
                    <Button
                      style={{
                        padding: "2px 12px",
                        background: "#35CF85",
                        borderRadius: 20,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: "rgba(250, 250, 250, 1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Ganti
                      </Typography>
                      <KeyboardArrowDownRoundedIcon
                        style={{
                          color: "rgba(250, 250, 250, 1)",
                          marginLeft: 3,
                        }}
                      />
                    </Button>
                  ) : (
                    <ChevronRightRoundedIcon
                      style={{ color: "#CCCCCC", verticalAlign: "top" }}
                    />
                  )}
                </Grid>
              </Button>
              <input
                ref={register({ required: true })}
                name="selectedPaymentId"
                type="hidden"
                value={selectedPaymentMethod ? selectedPaymentMethod.id : ""}
              />
              {errors.selectedPaymentId && (
                <span style={{ color: "red" }}>
                  Silahkan pilih metode pembayarannya
                </span>
              )}
              {selectedProductId === process.env.INFAK_ID && (
                <React.Fragment>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 12,
                      color: 666666,
                      marginTop: 12,
                    }}
                  >
                    Tipe Donatur
                  </Typography>
                  <Grid
                    onClick={() => setCardDisplay("donationType")}
                    style={{
                      display: "flex",
                      padding: "12px 5px",
                      marginBottom: 6,
                      cursor: "pointer",
                      width: "100%",
                      justifyContent: "start",
                    }}
                  >
                    <Grid item xs={6} style={{ alignSelf: "center" }}>
                      <Grid style={{ display: "flex", alignItems: "center" }}>
                        <GreenRadio
                          checked={donorType === "INDIVIDUAL"}
                          onChange={handleChangeType}
                          value={"INDIVIDUAL"}
                          name="type"
                          inputProps={{ "aria-label": "A" }}
                          style={{ padding: 0, paddingRight: 9 }}
                        />
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            textTransform: "uppercase",
                          }}
                        >
                          Personal
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} style={{ alignSelf: "center" }}>
                      <Grid style={{ display: "flex", alignItems: "center" }}>
                        <GreenRadio
                          checked={donorType === "BUSINESS"}
                          onChange={handleChangeType}
                          value={"BUSINESS"}
                          name="type"
                          inputProps={{ "aria-label": "B" }}
                          style={{ padding: 0, paddingRight: 9 }}
                        />
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            textTransform: "uppercase",
                          }}
                        >
                          Kelompok/ Perusahaan
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}

              <Controller
                control={control}
                defaultValue=""
                name="name"
                rules={{ required: true, minLength: 3 }}
                render={({ onChange }) => (
                  <input
                    value={name}
                    type="text"
                    onChange={(e) => {
                      onChange(e);
                      setName(e.target.value);
                      setValue("name", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    style={{
                      padding: "14px 12px",
                      fontSize: 14,
                      fontWeight: 400,
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #CCCCCC",
                      outline: "none",
                      width: "100%",
                      textAlign: "start",
                      borderRadius: 2,
                      marginTop: 14,
                    }}
                    placeholder={"Masukkan Nama Lengkap"}
                  />
                )}
              />

              {errors.name?.type === "required" && (
                <span style={{ color: "red" }}>
                  Nama mohon diisi. Bisa tidak ditampilkan
                </span>
              )}
              {errors.name?.type === "minLength" && (
                <span style={{ color: "red" }}>
                  Nama harus lebih dari 3 karakter
                </span>
              )}
              <Controller
                control={control}
                defaultValue=""
                name="phone"
                countryCodeEditable={false}
                rules={{ required: true, minLength: 8 }}
                render={({ onChange }) => (
                  <MyPhoneInput
                    value={inputPhoneValue && inputPhoneValue.phone}
                    values={inputPhoneValue}
                    style={{ marginTop: 12, backgroundColor: "#f5f5f5" }}
                    placeholder="Enter phone number"
                    onChange={(v) => {
                      onChange(v);
                      setValue("phone", v, { shouldValidate: true });
                      setInputPhoneValue(v);
                    }}
                  />
                )}
              />
              {/* <PhoneInput
                    value={phone}
                    inputProps={{ name: "phone" }}
                    countryCodeEditable={false}
                    country={"id"}
                    onChange={(p) => {
                      onChange(p);
                      setPhone(p);
                    }}
                    style={{
                      marginTop: 12,
                      fontWeight: 400,
                    }}
                    placeholder="Masukkan Nomor Ponsel"
                  /> */}
              {errors.phone?.type === "required" && (
                <span style={{ color: "red" }}>
                  Nomor ponsel mohon diisi. Tidak akan ditampilkan
                </span>
              )}
              {inputPhoneValue && inputPhoneValue?.phone?.length < 6 && (
                <span style={{ color: "red" }}>
                  Nomor ponsel harus lebih dari 6 karakter
                </span>
              )}
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  color: 666666,
                  margin: "12px 0px 3px 0px",
                }}
              >
                Email (Opsional)
              </div>
              <Controller
                control={control}
                defaultValue=""
                name="email"
                rules={{
                  // required: true,
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "invalid email address",
                  },
                }}
                render={({ onChange }) => (
                  <input
                    value={email}
                    type="text"
                    onChange={(e) => {
                      onChange(e);
                      setEmail(e.target.value);
                      setValue("email", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    style={{
                      padding: "14px 12px",
                      fontSize: 14,
                      fontWeight: 400,
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #CCCCCC",
                      outline: "none",
                      width: "100%",
                      textAlign: "start",
                      borderRadius: 2,
                      marginTop: 14,
                    }}
                    placeholder={"Masukkan Email (Opsional)"}
                  />
                )}
              />

              {email && errors?.email?.message && (
                <span style={{ color: "red" }}>
                  Mohon input email yang valid!
                </span>
              )}
              {selectedProductId === process.env.ZAKAT_ID && (
                <React.Fragment>
                  <Grid
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      marginTop: 14,
                    }}
                  >
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <GreenCheckbox
                          checked={isPotongPajak}
                          onChange={handleIsPotongPajak}
                          name="isPotongPajak"
                          ref={register}
                          // value={true}
                          fontSize="small"
                          style={{ padding: 0 }}
                        />
                      }
                    />
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 700,
                        color: "#4D4D4D",
                        marginLeft: 8,
                      }}
                    >
                      Potong Pajak
                    </Typography>
                  </Grid>
                  {isPotongPajak && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                        marginTop: 14,
                      }}
                    >
                      <Controller
                        control={control}
                        defaultValue=""
                        name="npwp"
                        rules={{ required: true }}
                        render={({ onChange }) => (
                          <input
                            value={npwp}
                            type="text"
                            onChange={(e) => {
                              onChange(e);
                              setNpwp(e.target.value);
                              setValue("npwp", e.target.value, {
                                shouldValidate: true,
                              });
                            }}
                            style={{
                              padding: "14px 12px",
                              fontSize: 14,
                              fontWeight: 400,
                              backgroundColor: "#F5F5F5",
                              border: "1px solid #CCCCCC",
                              outline: "none",
                              width: "100%",
                              textAlign: "start",
                              borderRadius: 2,
                            }}
                            placeholder={"NPWP"}
                          />
                        )}
                      />

                      {errors.npwp?.type === "required" && (
                        <span style={{ color: "red" }}>NPWP harus diisi!</span>
                      )}
                    </Grid>
                  )}
                </React.Fragment>
              )}
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <GreenCheckbox
                      checked={isHambaAllah}
                      onChange={handleIsHambaAllah}
                      name="isHambaAllah"
                      ref={register}
                      // value={true}
                      fontSize="small"
                      style={{ padding: 0 }}
                    />
                  }
                />
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: "#4D4D4D",
                    marginLeft: 8,
                  }}
                >
                  Donasi sebagai Hamba Allah
                </Typography>
              </Grid>
              <Button
                type="submit"
                form="form-donation"
                style={{
                  backgroundColor: customPrice < 1 ? "#B8B8B8" : "#2DBE78",
                  marginTop: 22,
                  marginBottom: 16,
                  width: "100%",
                  color: "#FAFAFA",
                  padding: 12,
                  textTransform: "inherit",
                  fontWeight: 600,
                }}
              >
                Bismillah Donasi Sekarang
              </Button>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "rgb(109 109 109)",
                }}
              >
                Dana yang didonasikan melalui infakonline.id bukan bersumber dan
                bukan untuk tujuan sesuatu yang dilarang Allah seperti
                terorisme, korupsi, pencucian uang (money laundry), dll.
              </Typography>
            </form>
          </>
        </Grid>
      )}
    </>
  );
}

export default DonationCard;
