import React from "react";
import { Container } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { Spin } from "antd";
import "antd/dist/antd.css";
import dynamic from "next/dynamic";
import BannerSectionOne from "../components/BannerSectionOne";
import { axiosInstance } from "../config/axios-instance";

function Home() {
  // const BannerSectionOne = dynamic(
  //   () => import("../components/BannerSectionOne"),
  //   {
  //     loading: () => {
  //       return (
  //         <div
  //           style={{
  //             minHeight: "50vh",
  //             alignItems: "center",
  //             justifyContent: "center",
  //             display: "flex",
  //             zIndex: 9999,
  //           }}
  //         >
  //           <Spin size="large" />
  //         </div>
  //       );
  //     },
  //   }
  // );
  const BannerSectionTwo = dynamic(
    () => import("../components/BannerSectionTwo"),
    {
      loading: () => {
        return (
          <div
            style={{
              minHeight: "50vh",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              zIndex: 9999,
            }}
          >
            <Spin size="large" />
          </div>
        );
      },
    }
  );
  const [isMobile, setIsMobile] = React.useState(false);
  const isMob = useMediaQuery({ maxWidth: 1024 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [recentArticle, setRecentArticle] = React.useState(true);
  const [categoryProductId, setCategoryProductId] = React.useState("");
  const [campaigns, setCampaigns] = React.useState([]);

  const initializeApp = async () => {
    const getArticles = await axiosInstance.get(
      "customer/cms/articles?perPage=3"
    );
    setRecentArticle(getArticles.data.data);

    const campaigns = await axiosInstance.get(
      "/customer/ecommerce/products?type=DONATION&page=1&perPage=6"
    );

    setCampaigns(campaigns.data.data);

    setIsLoading(false);
  };

  React.useEffect(() => {
    initializeApp();
  }, []);

  React.useEffect(() => {
    if (isMob) {
      setIsMobile(isMob);
    } else {
      setIsMobile(false);
    }
  }, [isMob]);

  const handleCalculate = async (price, id) => {
    console.log("id", id);
    try {
      const res = await axiosInstance.post(
        "customer/ecommerce/orders/calculate?showAllPayment=true",
        {
          ...(id === process.env.ZAKAT_ID
            ? {
                items: [
                  {
                    productId: id,
                    quantity: 1,
                    customPrice: Number(price),
                  },
                ],
              }
            : {
                item: {
                  productId: id,
                  quantity: 1,
                  customPrice: Number(price),
                },
              }),
          contact: {
            phone: "",
            email: null,
          },
          shipping: null,
          billing: {
            name: "test",
            phone: "",
            email: "",
            address: "",
            postcode: "",
            country: "",
            latitude: 0,
            longitude: 0,
            note: "",
            province: "",
            cityId: "",
            city: "",
          },
          voucherCode: "",
          customerNote: "",
          isAnonymousDonor: false,
          metadata: null,
        }
      );
      return res;
    } catch (e) {
      console.log(e.response);
    }
    return null;
  };

  const handleSubmit = async (data) => {
    try {
      const res = await axiosInstance
        .post("customer/ecommerce/orders/guest-checkout", data)
        .then((res) => {
          window.location.href = res.data.data.payments[0].url;
        })
        .catch((err) => {
          console.log(err);
        });
      return res;
    } catch (error) {
      console.log(error.response.message);
      // alert("Ups, gagal donasi " + error?.response?.message);
    }
    return null;
  };

  return (
    <Spin spinning={isLoading}>
      <Container
        style={{
          padding: 0,
          maxWidth: 9999,
        }}
      >
        {isMob ? (
          <div>
            <div style={{ paddingTop: 52 }}>
              <div
                style={{
                  // paddingTop: "25px",
                  backgroundImage: isLoading
                    ? null
                    : "url(/Frame/HomepageResp.svg)",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <BannerSectionOne
                  handleCalculate={handleCalculate}
                  handleSubmit={handleSubmit}
                />
              </div>
              <BannerSectionTwo
                handleClickCampaign={() => setIsLoading(true)}
                campaigns={campaigns}
                recentArticle={recentArticle}
              />
            </div>
            {/* )} */}
          </div>
        ) : (
          <div>
            <>
              <div
                style={{
                  paddingTop: "70px",
                  paddingBottom: "100px",
                  backgroundImage: isLoading
                    ? null
                    : "url(/Frame/HomepageMainFrame.png)",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <BannerSectionOne
                  handleCalculate={handleCalculate}
                  handleSubmit={handleSubmit}
                />
              </div>

              <BannerSectionTwo
                handleClickCampaign={() => setIsLoading(true)}
                campaigns={campaigns}
                recentArticle={recentArticle}
              />
            </>
            {/* )} */}
          </div>
        )}
      </Container>
    </Spin>
  );
}

export default Home;
