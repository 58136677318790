const PrevIconButton = ({className, ...props}) => {
    return (
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
        <g filter="url(#filter0_d_6419_13778)">
        <circle cx="31" cy="31" r="29" fill="#2DBE78"/>
        </g>
        <path d="M35.707 21.7426C35.8692 21.9045 35.9979 22.0968 36.0857 22.3085C36.1735 22.5202 36.2188 22.7472 36.2188 22.9763C36.2188 23.2055 36.1735 23.4325 36.0857 23.6442C35.9979 23.8559 35.8692 24.0482 35.707 24.2101L28.917 31.0001L35.707 37.7901C36.0342 38.1173 36.218 38.5611 36.218 39.0238C36.218 39.4866 36.0342 39.9304 35.707 40.2576C35.3798 40.5848 34.936 40.7686 34.4732 40.7686C34.0105 40.7686 33.5667 40.5848 33.2395 40.2576L25.207 32.2251C25.0447 32.0632 24.916 31.8709 24.8282 31.6592C24.7404 31.4475 24.6952 31.2205 24.6952 30.9914C24.6952 30.7622 24.7404 30.5352 24.8282 30.3235C24.916 30.1118 25.0447 29.9195 25.207 29.7576L33.2395 21.7251C33.9045 21.0601 35.0245 21.0601 35.707 21.7426Z" fill="white"/>
        <defs>
        <filter id="filter0_d_6419_13778" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="6" dy="6"/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.159735 0 0 0 0 0.138898 0 0 0 0 0.138898 0 0 0 0.09 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6419_13778"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6419_13778" result="shape"/>
        </filter>
        </defs>
        </svg>

    )
}

export default PrevIconButton