import React, { useState } from "react";
import { Grid, Typography, Button, Dialog } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import Divider from "@material-ui/core/Divider";
import { Modal } from "antd";
import styles from "../../styles/CalculatorZakat.module.css";

function CalculatorZakat(props) {
  const { control, errors, handleSubmit, setValue, getValues } = useForm();
  const [zakatValue, setZakatValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalProperty, setTotalProperty] = useState(0);
  const [totalNisab, setTotalNisab] = useState(0);

  const [form, setForm] = useState([
    {
      label: "Uang Tunai / Tabungan",
      dialogLabel: "Uang Tunai dan Tabungan",
      value: null,
      unit: "Rp",
      unitType: "prefix",
      name: "tunai",
      fullWidth: true,
      calculted: true,
      displayDialog: true,
    },
    {
      fullWidth: false,
      displayDialog: true,
      customDialog: true,
      content: [
        {
          label: "Emas murni",
          dialogLabel: "Emas murni",
          fullWidth: false,
          value: null,
          unit: "Gram",
          unitType: "suffix",
          name: "emasWeight",
        },
        {
          label: "Perak",
          dialogLabel: "Perak",
          fullWidth: false,
          value: null,
          unit: "Gram",
          unitType: "suffix",
          name: "perakWeight",
        },
      ],
    },
    {
      fullWidth: false,
      displayDialog: false,
      customDialog: false,
      note: (
        <p style={{ marginBottom: 0, fontSize: 12 }}>
          Harga Emas dan Perak dapat dilihat di{" "}
          <span
            onClick={() =>
              window.open("https://www.logammulia.com/id", "_blank")
            }
            style={{ color: "#2DBE78", cursor: "pointer" }}
          >
            https://logammulia.com/id
          </span>
        </p>
      ),
      content: [
        {
          label: "Harga Emas murni",
          fullWidth: false,
          value: null,
          unit: "Rp",
          unitType: "prefix",
          name: "emas",
          note: (
            <p style={{ marginBottom: 0, fontSize: 12 }}>
              Harga emas murni per gram
            </p>
          ),
        },
        {
          label: "Harga Perak",
          fullWidth: false,
          value: null,
          unit: "Rp",
          unitType: "prefix",
          name: "perak",
          note: (
            <p style={{ marginBottom: 0, fontSize: 12 }}>
              Harga perak per gram
            </p>
          ),
        },
      ],
    },
    {
      label: "Properti (tanah, bangunan, kendaraan yang dikomersialkan)",
      dialogLabel: "Properti",
      value: null,
      displayDialog: true,
      unit: "Rp",
      unitType: "prefix",
      name: "properti",
      fullWidth: true,
      calculted: true,
    },
    {
      label: "Piutang Lancar",
      dialogLabel: "Piutang Lancar",
      displayDialog: true,
      value: null,
      unit: "Rp",
      unitType: "prefix",
      name: "piutang",
      fullWidth: true,
      calculted: true,
    },
    {
      label: "Investasi/ Aset Perusahaan",
      dialogLabel: "Investasi/ Aset Perusahaan",
      displayDialog: true,
      value: null,
      unit: "Rp",
      unitType: "prefix",
      name: "investasi",
      fullWidth: true,
      calculted: true,
    },
  ]);

  const handleCalculate = () => {
    const nisab = 85 * form[2].content[0].value;
    const emasTotal = form[1].content[0].value * form[2].content[0].value;
    const perakTotal = form[1].content[1].value * form[2].content[1].value;

    const calculatedForm = form.filter((item) => item.calculted);

    const totalCalculated = calculatedForm
      .map((item) => Number(item.value) || 0)
      .reduce((prev, curr) => prev + curr, 0);

    const total = totalCalculated + emasTotal + perakTotal;
    setTotalProperty(total);
    setTotalNisab(nisab);

    if (total < nisab) {
      setZakatValue(0);
      setOpenDialog(true);
      return;
    }
    setZakatValue(Math.ceil(total / 40));
    setOpenDialog(true);
  };

  const disabledCaclculate =
    !form?.[2]?.content?.[0]?.value || !form?.[2]?.content?.[1]?.value;

  return (
    <Grid style={{ padding: "35px 20px" }}>
      <Grid
        onClick={() => props.setDisplayCard()}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <ArrowBackIcon />
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginLeft: 8,
          }}
        >
          Kembali
        </Typography>
      </Grid>
      <Divider style={{ margin: 15 }} />
      <div
        style={{
          borderRadius: 2,
          backgroundColor: "#F2F3F4",
          fontWeight: 700,
          fontSize: 12,
          color: "#4d4d4d",
          padding: "5px 10px",
          margin: "10px 0px",
        }}
      >
        Kalkulator Zakat
      </div>
      <Grid container spacing={1}>
        {form.map((item, index) => {
          if (item.fullWidth) {
            return (
              <Grid
                item
                xs={12}
                style={{
                  padding: "8px 5px",
                  borderRadius: 2,
                }}
              >
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    item
                    xs={6}
                    style={{
                      fontWeight: 400,
                      fontSize: 12,
                      color: "#4D4D4D",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    padding: "7px 11px",
                    borderRadius: 5,
                    marginTop: 6,
                    border: "1px solid #CCCCCC",
                  }}
                >
                  {item.unitType === "prefix" && (
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: "#808080",
                      }}
                    >
                      {item.unit}
                    </Typography>
                  )}
                  <Controller
                    control={control}
                    name={item.name}
                    rules={{ required: true }}
                    render={({ onChange, onBlur }) => (
                      <NumberFormat
                        placeholder="0"
                        thousandSeparator={true}
                        style={{
                          border: "none",
                          textAlign: "left",
                          backgroundColor: "inherit",
                          fontWeight: "400",
                          fontSize: 12,
                          color: "#808080",
                          width: "100%",
                        }}
                        value={item.value}
                        onValueChange={(values) => {
                          const { value } = values;
                          setValue(item.name, value, {
                            shouldValidate: true,
                          });
                          let newForm = form;
                          newForm[index].value = value;
                          setForm(newForm);
                        }}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={(e) => {
                          onBlur(e);
                        }}
                      />
                    )}
                  />
                  {item.unitType === "suffix" && (
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: "#808080",
                      }}
                    >
                      {item.unit}
                    </Typography>
                  )}
                </div>
                {item.note && item.note}
              </Grid>
            );
          }
          return (
            <React.Fragment>
              {item.content.map((itemContent, itemContentIndex) => (
                <Grid
                  item
                  xs={6}
                  style={{
                    padding: "8px 5px",
                    borderRadius: 2,
                  }}
                >
                  <Grid
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      item
                      xs={6}
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: "#4D4D4D",
                      }}
                    >
                      {itemContent.label}
                    </Typography>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      padding: "7px 11px",
                      borderRadius: 5,
                      marginTop: 6,
                      border: "1px solid #CCCCCC",
                    }}
                  >
                    {itemContent.unitType === "prefix" && (
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#808080",
                        }}
                      >
                        {itemContent.unit}
                      </Typography>
                    )}

                    <Controller
                      control={control}
                      name={itemContent.name}
                      rules={{ required: true }}
                      render={({ onChange, onBlur }) => (
                        <NumberFormat
                          placeholder="0"
                          thousandSeparator={true}
                          style={{
                            border: "none",
                            textAlign: "left",
                            backgroundColor: "inherit",
                            fontWeight: "400",
                            fontSize: 12,
                            color: "#808080",
                            width: "100%",
                          }}
                          value={itemContent.value}
                          onValueChange={(values) => {
                            const { value } = values;
                            setValue(itemContent.name, value, {
                              shouldValidate: true,
                            });
                            let newForm = form;
                            newForm[index].content[itemContentIndex].value =
                              value;
                            setForm(newForm);
                          }}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          onBlur={(e) => {
                            onBlur(e);
                          }}
                        />
                      )}
                    />
                    {itemContent.unitType === "suffix" && (
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#808080",
                        }}
                      >
                        {itemContent.unit}
                      </Typography>
                    )}
                  </div>
                  {itemContent.note && itemContent.note}
                </Grid>
              ))}
              {item.note && (
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "8px 5px",
                    borderRadius: 2,
                  }}
                >
                  {item.note}
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>

      <div
        onClick={handleCalculate}
        disabled={
          !form?.[2]?.content?.[0]?.value || !form?.[2]?.content?.[1]?.value
        }
        style={{
          padding: 12,
          marginTop: 15,
          backgroundColor: disabledCaclculate ? "#B8B8B8" : "#2DBE78",
          borderRadius: 5,
          textAlign: "center",
          color: "#fafafa",
          fontWeight: 600,
          fontSize: 16,
          cursor: "pointer",
        }}
      >
        Hitung Zakat
      </div>

      <Dialog
        onClose={() => setOpenDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={() => setOpenDialog(false)}
              src="/Icon/DialogClose.svg"
            />
          </div>
          <Grid
            container
            spacing={1}
            style={{
              paddingBottom: 10,
              marginBottom: 10,
              borderBottom: "1px solid #CCCCCC",
            }}
          >
            {form.map((item) => {
              if (!item.displayDialog) {
                return;
              }
              if (item.displayDialog && item.customDialog && item.content) {
                return (
                  <React.Fragment>
                    {item.content.map((itemContent) => {
                      if (itemContent.name === "emasWeight") {
                        return (
                          <React.Fragment>
                            <Grid item xs={8}>
                              <div
                                className={
                                  styles["calculator-dialog-left-content"]
                                }
                              >
                                {itemContent.label}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                className={
                                  styles["calculator-dialog-right-content"]
                                }
                              >
                                Rp{" "}
                                {(
                                  (itemContent?.value || 0) *
                                  form[2].content[0].value
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </div>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                      if (itemContent.name === "perakWeight") {
                        return (
                          <React.Fragment>
                            <Grid item xs={8}>
                              <div
                                className={
                                  styles["calculator-dialog-left-content"]
                                }
                              >
                                {itemContent.label}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                className={
                                  styles["calculator-dialog-right-content"]
                                }
                              >
                                Rp{" "}
                                {(
                                  (itemContent?.value || 0) *
                                  form[2].content[1].value
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </div>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment>
                          <Grid item xs={8}>
                            <div
                              className={
                                styles["calculator-dialog-left-content"]
                              }
                            >
                              {itemContent.label}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div
                              className={
                                styles["calculator-dialog-right-content"]
                              }
                            >
                              Rp{" "}
                              {(itemContent?.value || 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment>
                  <Grid item xs={8}>
                    <div className={styles["calculator-dialog-left-content"]}>
                      {item.label}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles["calculator-dialog-right-content"]}>
                      Rp{" "}
                      {(item?.value || 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </div>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={8}>
              <div
                style={{ fontSize: 14 }}
                className={styles["calculator-dialog-left-content"]}
              >
                <b>Total Harta Anda</b>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{ fontSize: 14 }}
                className={styles["calculator-dialog-right-content"]}
              >
                Rp{" "}
                {totalProperty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </div>
            </Grid>

            <Grid item xs={8}>
              <div className={styles["calculator-dialog-left-content"]}>
                Nisab (85 x Harga Emas)
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles["calculator-dialog-right-content"]}>
                Rp {totalNisab.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  color: "#2DBE78",
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                {zakatValue > 0
                  ? "Anda Wajib Zakat, Harta Anda Memenuhi Nisab"
                  : "Anda Tidak Wajib Zakat, Harta Anda Belum Memenuhi Nisab"}
              </div>
            </Grid>

            <Grid item xs={8}>
              <div className={styles["calculator-dialog-left-content"]}>
                <b>Total Harta yang Kena Zakat</b>
                <p>2,5% dari Total Harta Anda</p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles["calculator-dialog-right-content"]}>
                Rp{" "}
                {zakatValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </div>
            </Grid>
          </Grid>

          <div>
            {zakatValue > 0 && (
              <div
                onClick={() => props.handleZakat(zakatValue)}
                style={{
                  padding: 12,
                  width: "100%",
                  margin: "10px 0px",
                  backgroundColor: "#2DBE78",
                  borderRadius: 5,
                  textAlign: "center",
                  color: "#fafafa",
                  fontWeight: 600,
                  fontSize: 16,
                  cursor: "pointer",
                }}
              >
                Bayar Zakat Sekarang
              </div>
            )}

            <div
              onClick={() => setOpenDialog(false)}
              style={{
                padding: 12,
                width: "100%",
                color: "#2DBE78",
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                textAlign: "center",
                fontWeight: 600,
                fontSize: 16,
                cursor: "pointer",
                border: "1px solid #2DBE78",
              }}
            >
              Hitung Ulang
            </div>
          </div>
        </div>
      </Dialog>
    </Grid>
  );
}

export default CalculatorZakat;
